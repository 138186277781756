import { signOut } from "next-auth/react";
import Head from "next/head";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { HotKeys } from "react-hotkeys";
import useSWR from "swr";
import * as gtag from "../utils/gtag";
import ReportBug from "./reportBug";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/nextjs"
import { CrossButton, HamburgerMenu, LogoutIcon, ProjectsIcon, SettingsIcon, SidebarHomeIcon } from "./Icons";

function handleFeedback() {
  //TODO why are we throwing this error without catching it ???
  try {
    throw Error(`Got user feedback ${+new Date()}`);
  } catch (err) {
    Sentry.captureException(err);
  }
}

const keyMap = {
  FEEDBACK: "command+i",
};

const handlers = {
  FEEDBACK: handleFeedback,
};

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const UserLayout = function (props) {
  const { data: configData, error: configError } = useSWR(
    `/api/config`,
    fetcher
  );
  const router = useRouter();
  const [contextMenu, setContextMenu] = useState(null);
  const [reportBugShown, showReportBug] = useState({ state: false });
  const [signedOut, setSignedout] = useState(false);
  const serializedFlags = JSON.stringify(props.flags);
  const serializedDistinctId = JSON.stringify(props.distinctId);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  function onSignOut() {
    gtag.event({
      action: "signedOut",
      category: "workspace",
    });

    signOut({
      callbackUrl: `${window.location.origin}/workspace`,
    });

    setSignedout(true)
  }

  useEffect(() => {
    window.reportBug = (eventId) => {
      showReportBug({
        state: true,
        eventId,
      });
    };

    window.onerror = (message, url, line, col, error) => {

    }
  }, []);

  useEffect(() => {
    if (props.session) {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "aqf1x1od",
        custom_launcher_selector: ".btn-chat-with-us",
        email: props.session.user.email,
        hide_default_launcher: true,
      };

      if (window.Intercom) {
        window.Intercom("update", window.intercomSettings);
      }

      window.beatovenUserEmail = props.session.user.email;

      mixpanel.identify(props.session.user.email);

      Sentry.setUser({ email: props.session.user.email });

      if (window.sendinblue) {
        window.sendinblue.identify(props.session.user.email);
      }
    }
  }, [props.session]);

  if (!configData) return <div />;

  function rediretHandler(link) {
    router.push(link);
    setSidebarOpen(false);
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      {/* <PHProvider> */}
      <div className="h-full w-full font-mulish bg-black-eerie text-gray">
        <Head>
          <title>Beatoven</title>
          <meta name="description" content="Beatoven" />
          <link rel="icon" href="/favicon.ico" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,400&family=Play:wght@400;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700;800;900&display=swap"
            rel="stylesheet"
          ></link>
          <script
            src="https://browser.sentry-cdn.com/6.10.0/bundle.min.js"
            integrity="sha384-nsIkfmMh0uiqg+AwegHcT1SMiPNWnhZmjFDwTshLTxur6ZPNaGT8vwT+vHwI5Jag"
            crossorigin="anonymous"
          />
          <script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GTM_ID}`}
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-TN84V5F"></script>
          <script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${gtag.GTM_ID}', {
                        page_path: window.location.pathname,
                      });
                  `,
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `

    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/aqf1x1od'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.setAttribute("crossorigin", "anonymous");s.async=true;s.src='https://widget.intercom.io/widget/aqf1x1od';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
            }}
          ></script>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                  (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.setAttribute("crossorigin","anonymous");t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_MS_CLARITY_ID}");
            if (window.beatovenUserEmail) { window.clarity("set", "email", "${window.beatovenUserEmail}") };
            `,
            }}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function() {
                window.sib = { equeue: [], client_key: "${process.env.NEXT_PUBLIC_BREVO_CLIENT_ID}" };
                /* OPTIONAL: email for identify request*/
                window.sib.email_id = window.beatovenUserEmail;
                window.sendinblue = {}; for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) { (function(k) { window.sendinblue[k] = function() { var arg = Array.prototype.slice.call(arguments); (window.sib[k] || function() { var t = {}; t[k] = arg; window.sib.equeue.push(t);})(arg[0], arg[1], arg[2]);};})(j[i]);}var n = document.createElement("script"),i = document.getElementsByTagName("script")[0]; n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
            })();`,
            }}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                  window.__FLAG_DATA__ = ${serializedFlags}; window.__PH_DISTINCT_ID__ = ${serializedDistinctId};`
            }}
          />
        </Head>
        {router.pathname !== "/welcome" && props.session && (
          <>
            <button
              className={`hover:opacity-100 opacity-90 absolute right-4 top-5 h-8 z-50 sm:hidden`}
              onClick={(e) => {
                e.stopPropagation();
                const rect = e.target.getBoundingClientRect();

                setContextMenu({
                  component: (
                    <div className="absolute bg-black-eerie select-none pl-1  border border-neutral-700 rounded-md absolute z-50 w-64">
                      <ul className="text-left w-full rounded-2xl">
                        <li className="border-neutral-700 border-b text-neutral-300 opacity-80 border-b text-white p-3 block">
                          <div class="text-sm h-auto w-full overflow-wrap">
                            <span class="block text-neutral-500">
                              Signed in as
                            </span>
                            <span class="block truncate">
                              {props.session.user.email}
                            </span>
                          </div>
                        </li>
                        <li className="cursor-pointer p-3 text-sm border-neutral-700 border-b">
                          <a
                            className="rounded-t text-white font-normal block whitespace-no-wrap hover:opacity-100 opacity-80"
                            href="/settings"
                          >
                            Settings
                          </a>
                        </li>
                        <li
                          className="cursor-pointer p-3 hover:opacity-100 opacity-80 font-normal text-white"
                          onClick={onSignOut}
                        >
                          Sign out
                        </li>
                      </ul>
                    </div>
                  ),
                  style: {
                    top: rect.top + 30,
                    left: rect.left - 220,
                  },
                });
              }}
            >
              <img
                crossOrigin="anonymous"
                src={props.session.user.image || `/Profile.png`}
                alt="Profile"
                onError={(e) => {
                  e.target.src = "/Profile.png";
                  e.target.onError = null;
                }}
                className={`inline rounded-full w-8 h-8`}
              />
              <span class="pr-5 inline bg-down-suffix-icon bg-no-repeat bg-center" />
            </button>
            <div className="hidden sm:block sm:absolute sm:right-4 sm:top-7 z-20 cursor-pointer" onClick={() => setSidebarOpen(!sidebarOpen)}>
              <HamburgerMenu />
            </div>
            {sidebarOpen && (
              <div className="absolute w-full z-50 h-full bg-gunmetal-darker">
                <div className="flex items-center justify-between mt-4 px-2">
                  <div className="flex items-center gap-2">
                    <img
                      crossOrigin="anonymous"
                      src={props.session.user.image || `/Profile.png`}
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = "/Profile.png";
                        e.target.onError = null;
                      }}
                      className={`inline rounded-full w-8 h-8`}
                    />
                    <p className="font-mulish font-normal text-sm text-white opacity-60">Signed in as {props.session.user.email}</p>
                  </div>
                  <button className="cursor-pointer" onClick={() => setSidebarOpen(false)}><CrossButton /></button>
                </div>
                <div className="mt-4">
                  <ul>
                    <li className={`${router.pathname === "/workspace" ? "opacity-80 bg-gunmetal" : ""} "cursor-pointer flex items-center gap-2 mb-3 font-poppins font-medium text-sm text-white opacity-60 hover:opacity-80 hover:bg-gunmetal py-2 px-2 rounded-lg "`}
                      onClick={() => rediretHandler("/workspace")}
                    >
                      <SidebarHomeIcon /> <span>Home</span>
                    </li>
                    <li className={`${router.pathname === "/projects" ? "opacity-80 bg-gunmetal" : ""} "cursor-pointer flex items-center gap-2 mb-3 font-poppins font-medium text-sm text-white opacity-60 hover:opacity-80 hover:bg-gunmetal py-2 px-2 rounded-lg "`}
                      onClick={() => rediretHandler("/projects")}
                    >
                      <ProjectsIcon /> <span>Projects</span>
                    </li>
                    <li className={`${router.pathname === "/settings" ? "opacity-80 bg-gunmetal" : ""} "cursor-pointer flex items-center gap-2 mb-3 font-poppins font-medium text-sm text-white opacity-60 hover:opacity-80 hover:bg-gunmetal py-2 px-2 rounded-lg "`}
                      onClick={() => rediretHandler("/settings")}
                    >
                      <SettingsIcon /> <span>Settings</span>
                    </li>
                    <li className="cursor-pointer flex items-center gap-2 mb-3 font-poppins font-medium text-sm text-white opacity-60 hover:opacity-80 hover:bg-gunmetal py-2 px-2 rounded-lg "
                      onClick={onSignOut}
                    >
                      <LogoutIcon /> <span>Sign out</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </>
        )}

        {contextMenu && (
          <div
            onClick={() => setContextMenu(null)}
            className="border-0 top-0 right-0 text-sm z-50 fixed h-screen w-screen"
          >
            <div
              className="bg-black w-auto rounded-md absolute"
              style={contextMenu.style}
            >
              {contextMenu.component}
            </div>
          </div>
        )}
        {/* <PostHogPageView session={props.session} signedOut={} /> */}
        {props.children}
        {reportBugShown.state && (
          <ReportBug
            dismiss={() => showReportBug({ state: false })}
            email={props.session.user.email}
            eventId={reportBugShown.eventId}
          />
        )}
      </div>
      {/* </PHProvider> */}
    </HotKeys>
  );
};

export default UserLayout;
